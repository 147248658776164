import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Talk from "../../components/talk";

import '../../components/index.scss'
import HomeLink from "../../components/home-link";

const XCUITestingPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "talks/this-is-how-we-do-it.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title = "this is how we do it - CSS";
  const summary = `As part of a large migration into a new platform, the UI team was tasked with defining and transmitting
      our standards to the entire company, as a Senior developer I was tasked with leading the effort and delivering
      the talk to the entire Engineering department.`;

  return <Layout>
    <SEO title={title} description={summary}/>
    <HomeLink/>
    <Talk
      imageQuery={imageQuery}
      name={title}
      year={2013}
      summary={summary}
      slidesURL={"https://docs.google.com/presentation/d/1sA24VMuDpQU9xo2ddXn9wgXVvpKdVbl0Ys6D7xABx10/edit?usp=sharing"}
    />
  </Layout>
};

export default XCUITestingPage
